import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import { QText } from "../../common/Fonts";

interface WeightInputFieldProps {
  placeholder: string;
  value: string;
  onChange: (fieldKey: string, value: string) => void;
  fieldKeys: {
    weightPounds: string;
    weightPounds1: string;
    weightPounds2: string;
    weightPounds3: string;
  };
  disabled?: boolean;
  className?: string;
}

export function WeightInputField(props: WeightInputFieldProps) {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputBlur = () => {
    // console.log("Input value on blur:", inputValue);

    const digits = [" ", " ", " "];
    for (let i = 0; i < inputValue.length; i++) {
      if (inputValue[i] !== "") {
        digits[3 - inputValue.length + i] = inputValue[i];
      }
    }
    // console.log("Updating weightPounds1:", digits[0]);
    // console.log("Updating weightPounds2:", digits[1]);
    // console.log("Updating weightPounds3:", digits[2]);
    // console.log("Updating weightPounds:", inputValue.trim());

    props.onChange(props.fieldKeys.weightPounds1, digits[0]);
    props.onChange(props.fieldKeys.weightPounds2, digits[1]);
    props.onChange(props.fieldKeys.weightPounds3, digits[2]);
    props.onChange(props.fieldKeys.weightPounds, inputValue.trim());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    const rawValue = e.target.value.slice(0, 3);
    setInputValue(rawValue);
  };

  return (
    <div className="text-box-container">
      <Input
        className={"text-box" + (props.className ? ` ${props.className}` : "")}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      {inputValue && (
        <div className="inline-placeholder">
          <QText level="placeholder">{props.placeholder}</QText>
        </div>
      )}
    </div>
  );
}
